body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-parent {
  display: flex;
  justify-content: center;
  .app-child {
    display: flex;
    flex-direction: column;
    margin: 5px;
    width: 600px;
    .input-parent {
      display: flex;
      flex-direction: column;
      margin: 5px;
      background-color: aliceblue;
      border-radius: 15px;
      .welcome-text {
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: 600;
        margin: 10px 10px 0 10px;
        line-height: 1.3em;
        text-align: center;
      }
      .sign-in-parent {
        display: flex;
        justify-content: center;
        .sign-in-child {
          margin: 10px;
        }
      }
      .input-child {
        display: flex;
        justify-content: center;
        margin: 10px;
        gap: 10px;
        height: 35px;
      }
    }
    .history-parent {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: 5px;
      .post-list {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .post-item {
          display: flex;
          flex-direction: row;
          background-color: aliceblue;
          border-radius: 15px;
          .post-author {
            width: 30%;
            margin: 10px;
            .post-picture {
              width: 64px;
              height: 64px;
              border-radius: 50%;
            }
            .post-name {
              font-size: 14px;
              font-weight: 600;
              line-height: 1.3em;
            }
          }
          .post-message {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 70%;
            margin: 10px 15px 10px 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            gap: 20px;
            .post-content {
              font-size: 20px;
              font-weight: 600;
              line-height: 1.3em;
              text-align: start;
            }
            .post-footer {
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;
              line-height: 1.3em;
              font-size: 14px;
              font-weight: 600;
              .post-control {
                background: none;
                color: inherit;
                border: none;
                padding: 0;
                font: inherit;
                cursor: pointer;
                outline: inherit;
              }
            }
          }
        }
      }
    }
  }
}

.minimal-input {
  box-sizing: border-box;
  width: 50%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;
}

.minimal-input:focus {
  outline: none;
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
}

.minimal-input::placeholder {
  color: #999;
}

.minimal-button {
  box-sizing: border-box;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  color: #333;
  cursor: pointer;
  transition:
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;
}

.minimal-button:hover {
  background-color: #e0e0e0;
}

.minimal-button:focus {
  outline: none;
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
}

.minimal-button:disabled {
  background-color: #ddd;
  border-color: #ccc;
  color: #999;
  cursor: not-allowed;
}

.minimal-spinner-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.minimal-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #333;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .app-child {
    width: 300px !important;
  }
}
